import React from "react"

export default function ResumeCard({ id, title, timeInterval, children }) {
  return (
    <div id={id} className="resume-card shadow-sm p-3 mb-4">
      <section className="d-flex justify-content-between mb-1">
        <span className="title">{title}</span>
        <span className="time-interval">{timeInterval}</span>
      </section>
      <hr />
      <div className="caption">{children}</div>
    </div>
  )
}
