import React from "react"
import ResumeCard from "./ResumeCard"
import TechnologiesList from "./TechnologiesList"

export default function ProjectCard({
  id,
  title,
  timeInterval,
  links,
  dependencies,
  children,
}) {
  const linkButtons =
    links &&
    links.map(item => {
      if (item.href) {
        return (
          <span className="project-links px-2 mx-1 mb-2 mt-3" key={item.name}>
            <a href={item.href} target="_blank" rel="noreferrer">
              &gt; {item.name}
            </a>
          </span>
        )
      }
      return <span className="project-links px-2 mx-1 mb-2 mt-3" key={item.name}>&gt; {item.name}</span>
    })

  const linkSection =
    linkButtons &&
    (linkButtons.length > 1 ? (
      <div className="d-flex flex-column flex-sm-row justify-content-center align-items-center">
        {linkButtons}
      </div>
    ) : (
      <div className="d-flex justify-content-center">{linkButtons}</div>
    ))

  const dependenciesSection = dependencies && (
    <TechnologiesList names={dependencies} />
  )

  return (
    <ResumeCard id={id} title={title} timeInterval={timeInterval}>
      <div>
        <span className="title">Description</span>
      </div>
      {children}
      {linkSection}
      {dependenciesSection && <hr />}
      {dependenciesSection}
    </ResumeCard>
  )
}
