import React from "react"

export default function TechnologiesList({ names }) {
  names.sort()
  
  const nameSpans = names.map(name => {
    return <span className="tech px-2 mb-2 mr-1" key={name}>{name}</span>
  })

  return <div className="technologies">{nameSpans}</div>
}
