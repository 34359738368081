import React from "react"
import Layout from "../components/Layout"
import Head from "../components/Head"
import ProjectCard from "../components/ProjectCard"
import ResumeCard from "../components/ResumeCard"
import LandingPageHero from "../components/LandingPageHero"
import links from "../shared_data/page_links"
import "../styles/global.css"

export default function Home() {
  return (
    <div className="pageContent" id="home">
      <Head />
      <Layout>
        <LandingPageHero buttonLinks={links.heroLinks} />

        <a id="resume" />
        <div className="resume mx-5">
          <h2 className="text-center display-4 d-none d-md-block">
            <span className="arrow">
              <i className="fas fa-caret-down"></i>
            </span>{" "}
            Resume
          </h2>
          <h2 className="text-center display-5 d-md-none mb-3">
            <span className="arrow">
              <i className="fas fa-caret-down"></i>
            </span>{" "}
            Resume
          </h2>

          <div className="resume-section">
            <h3 className="mb-3">Experience</h3>

            <ResumeCard
              title={"Software Engineer @ Microsoft"}
              timeInterval={"August 2020 — Present"}
            >
              <p>
                I currently work on Dynamics Fraud Protection, specifically with
                the backend distributed system supporting the web APIs for the
                Account Protection product. Here's a sampling of some of the
                projects I had the opportunity to work on:
              </p>
              <div className="caption">
                <ul className="ml-4 ml-sm-5">
                  <li>
                    Designed, led review of, and implemented a generalized fault
                    injection feature using C# code generators. Fault injection
                    helps us inject deterministic behavior during integration
                    tests for nondeterministic scenarios like responses from
                    HTTP clients.
                  </li>
                  <li>
                    Designed, led review of, and implemented a solution to correctly
                    select machine learning models to call during real-time assessment requests
                    to our API, taking advantage of pre-existing mechanisms.
                  </li>
                  <li>
                    Integrated my team's inbound API transaction payloads into a pre-existing Azure Cognitive
                    Search service for the overall product. Customers use a view of this to search and
                    review previous transactions.
                  </li>
                  <li>
                    Helped implement an internal mechanism to allow the
                    management of multiple tenant-specific machine learning models using
                    CosmosDB.
                  </li>
                  <li>
                    Helped integrate a localized instance of RocksDB to
                    circumvent network calls to a non-local service.
                  </li>
                  <li>
                    Helped prototype UX for a supply chain product idea using
                    TypeScript and React.
                  </li>
                  <li>
                    Led efforts to increase documentation, establish clear processes, and share knowledge across teams.
                    Also organized numerous morale events for my team.
                  </li>
                </ul>
              </div>
            </ResumeCard>

            <ResumeCard
              title={"Software Engineering Intern @ Microsoft"}
              timeInterval={"September — November 2019"}
            >
              <p>
                I built prototypes for voice interactions in Remote Assist, a
                HoloLens-based application. The first prototype provided
                feedback and suggestions in the field of vision based on a voice
                command attempt. The second prototype introduced natural
                language processing of voice commands through Azure APIs. This
                aimed to reduce cognitive load in remembering commands and
                derived command intent based on keywords and phrases.
              </p>
            </ResumeCard>

            <ResumeCard
              title={"Software Engineering Intern @ Tsugi, G.K."}
              timeInterval={"May — August 2019"}
            >
              <p>
                I implemented parsers for motion capture formats including BVH
                and the proprietary Autodesk FBX ASCII format. These parsers
                allow users to assign an animation curve to control a time-based
                parameter in the audio middleware application GameSynth.
                Additionally, I prototyped a wrapper for real-time Leap Motion
                data input to control audio parameters.
              </p>
            </ResumeCard>
          </div>

          <div className="resume-section">
            <a id="techProjects" />
            <h3 className="mb-3">Projects</h3>

            <ProjectCard
              id={"portfolio"}
              title={"Personal Portfolio Website"}
              timeInterval={"June 2020 — Present"}
              links={[{ name: "you're already here!" }]}
              dependencies={[
                "HTML",
                "Sass / CSS",
                "JavaScript",
                "Node.js",
                "Bootstrap",
                "Gatsby",
                "React",
              ]}
            >
              <p>
                I've been going through Colt Steele's
                <em> The Web Developer Bootcamp</em>, so I used a combination of
                Bootstrap and extra stuff I researched like vector graphics,
                Sass, and Gatsby. There's also some CI/CD work and a fair amount of nginx work
                with subdomains that I've been implementing (to showcase my previous projects 😎).
              </p>
            </ProjectCard>

            <ProjectCard
              id={"melodWe"}
              title={"melodWe"}
              timeInterval={"January — May 2020"}
              links={[
                {
                  name: "self-hosted version",
                  href: "https://melodweapp.vincentdinardo.com",
                },
              ]}
              dependencies={[
                "JavaScript",
                "Node.js",
                "Express.js",
                "Socket.IO",
                "MySQL",
                "Docker",
                "Nginx",
                "React",
                "Material-UI",
                "p5.js",
              ]}
            >
              <p>
                melodWe allows composers to congregate in virtual rooms and vote
                on each successive note in a melody in real time. Each note is
                randomly determined via weighted probability based on the number
                of votes. Both pitch and duration are determined separately in
                this way. This project was for my capstone course (a.k.a. senior
                project) at Virginia Tech, in collaboration with my project
                partner Lauren Ahart.
              </p>
              {/* <div className="projectDisclaimer mx-2 mx-md-5 my-2 p-2 rounded">
                <p>
                  <i class="fas fa-exclamation-triangle"/><strong><em> Please Note:</em></strong> I'm investigating how to 
                  stabilize the randomly crashing MySQL Docker containers. The database handles room assignments, so there might 
                  be times when you cannot access a composition room.
                </p>
              </div> */}
            </ProjectCard>

            <ProjectCard
              id={"surveyor"}
              title={"Surveyor"}
              timeInterval={"January — May 2019"}
              links={[
                {
                  name: "self-hosted version",
                  href: "https://surveyor.vincentdinardo.com",
                },
                // {
                //   name: "hosted version",
                //   href: "https://www.cs.cloud.vt.edu/projects/2019/dinardov/",
                // },
                // {
                //   name: "class website",
                //   href: "https://www.cs.cloud.vt.edu/#/",
                // },
              ]}
              dependencies={[
                "JavaScript",
                "Node.js",
                "Express.js",
                "JWT",
                "Redis",
                "Socket.IO",
                "MySQL",
                "Nginx",
                "React",
                "Material-UI",
              ]}
            >
              <p>
                A platform where admins can create surveys and all users can
                vote on surveys and see results change in real time. This
                project was for CS 3754 Cloud Software Development, and the
                instructor selected my implementation as an example. There are a few
                more pages for admins (and more logic), so let me know if you're a recruiter
                and you'd like to see that.
              </p>

              <div className="projectDisclaimer mx-2 mx-md-5 my-2 p-2 rounded">
                <p>
                  <i class="fas fa-exclamation-triangle"/><strong><em> Please Note:</em></strong> The class website that used to host my project
                  no longer exists, so I've transitioned to hosting the project myself.
                </p>
              </div>
            </ProjectCard>
          </div>

          {/* <div className="resume-section">
            <h3 className="mb-3">Leadership</h3>
          </div>

          <div className="resume-section">
            <h3 className="mb-3">Education</h3>
          </div>

          <div className="resume-section">
            <h3 className="mb-3">Honors + Awards</h3>
          </div> */}
        </div>
      </Layout>
    </div>
  )
}
