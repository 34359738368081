import React from "react"

const defaultViewBox = [0, 0, 880, 973]

// should have the containing object of this use
// className="overflow-hidden position-relative"
export default function WatermarkSVG({ sizeMultiplier = 1 }) {
  return (
    <div className="watermark-holder">
      <svg
        width="100%"
        height="100%"
        viewBox={defaultViewBox.reduce(
          (prev, curr) => prev + " " + sizeMultiplier * curr,
          ""
        )}
      >
        <g>
          <path
            d="M879.011,589.793l-879.011,382.711l0,-247.277l613.385,-241.16l265.626,-103.978l0,209.704Z"
            style={{ fill: "#fff", fillRule: "nonzero" }}
          />
          <path
            d="M879.011,380.089l-265.626,103.978l-613.385,-238.538l0,-245.529l879.011,380.089Z"
            style={{
              fill: "#fff",
              fillRule: "nonzero",
              fillOpacity: 0.76,
            }}
          />
        </g>
      </svg>
    </div>
  )
}
